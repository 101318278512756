<template>
    <div class="PrivateTemplate" id="mobile">
      <div class="viewContainer">
        <div id="lateralNav">
          <SideMenuMobile/>
        </div>
        <HamburgerMenu />
        <div id="mobileBodyContent">
          <HeadingMobile />
          <slot class="viewContainer__content"  name="private-mobile-template"></slot>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  components: {
    HeadingMobile: () => import('@/components/molecules/HeadingMobile/HeadingMobile.vue'),
    HamburgerMenu: () => import('@/components/molecules/HamburgerMenu/HamburgerMenu.vue'),
    SideMenuMobile: () => import('@/components/molecules/SideMenuMobile/SideMenuMobile.vue'),
  },
};
</script>
<style lang="sass" scoped>
@import './PrivateMobileTemplate.sass'
</style>
