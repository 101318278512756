<template>
  <private-mobile-template>
    <template v-slot:private-mobile-template>
      <div v-if="currentTab === 'request'"> <Request/> </div>
      <div v-if="currentTab === 'sent'"> <InvitationSent/> </div>
      <div v-if="currentTab === 'received'"> <InvitationReceived /> </div>
    </template>
  </private-mobile-template>
</template>

<script>
import PrivateMobileTemplate from '@/components/organisms/PrivateMobileTemplate/PrivateMobileTemplate.vue';
// import CreateRequest from '@/components/organisms/Request/CreateRequest.vue';
// import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import defaultRequest from '../defaultRequest';

export default {
  data() {
    return {
      request: defaultRequest,
      showCreateRequest: false,
      oldEditedRow: {
        id: null,
        notes: [],
        documents: [],
      },
    };
  },
  methods: {
    handleRevertEditedRowRequest() {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === this.oldEditedRow.id) {
          this.requestList[i].notes = this.oldEditedRow.notes;
          this.requestList[i].documents = this.oldEditedRow.documents;

          break;
        }
      }

      this.oldEditedRow.id = null;
      this.oldEditedRow.notes = [];
      this.oldEditedRow.documents = [];
    },

    showCreateView() {
      this.showCreateRequest = true;
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    closeCreateModal() {
      this.showCreateRequest = false;
    },

    closeCreateModalAfterAction() {
      this.closeCreateModal();
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    addData(data) {
      this.requestList.unshift(data);
      const updatedRequestList = this.removeDuplicates(this.requestList[0].documents);
      this.requestList.documents = updatedRequestList;
      this.handleRevertEditedRowRequest();
      this.incrementPagination();
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    clearDocumentsAndNotes() {
      defaultRequest.documents = [];
      defaultRequest.notes = [];
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    addRequest(data) {
      this.addData(data);
      this.clearDocumentsAndNotes();
      this.closeCreateModalAfterAction();
    },

  },
  computed: {
    requestList: {
      get() { return this.$store.state.request.request.requestList; },
      set(value) { this.$store.commit('request/setRequestList', value); },
    },
    pagination: {
      get() { return this.$store.state.request.request.pagination; },
      set(value) { this.$store.commit('request/setRequestPagination', value); },
    },
    currentTab() {
      return this.$store.getters['ui/getCurrentTab'];
    },
    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },
    isClinicRole() {
      return this.$store.getters['user/isClinicRole'];
    },
    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },
    isCollaborator() {
      return this.$store.getters['user/isCollaborator'];
    },
    isAllowCollaborations() {
      return this.$store.getters['user/isAllowCollaborations'];
    },

  },
  components: {
    // MyRequests: () => import('@/components/organisms/Request/tabs/MyRequests.vue'),
    // InvitationSent: () => import('@/components/organisms/Request/tabs/InvitationsSent.vue'),
    // InvitationReceived:
    // () => import('@/components/organisms/Request/tabs/InvitationsReceived.vue'),
    PrivateMobileTemplate,
    Request: () => import('@/components/organisms/Request/mobileTabs/Request.vue'),
    InvitationSent: () => import('@/components/organisms/Request/mobileTabs/InvitationSent.vue'),
    InvitationReceived: () => import('@/components/organisms/Request/mobileTabs/InvitationReceived.vue'),
    // CreateRequest,
    // HeadingBar,
  },
};
</script>
<style lang="sass" scoped>
  @import './RequestMobileView.sass'
</style>
